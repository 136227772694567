body {
    font-family: "Montserrat", sans-serif !important;
    overflow: hidden;
}

/* Стили для основного контейнера полосы прокрутки */
::-webkit-scrollbar {
    width: 5px; /* Ширина полосы прокрутки */
  }
  
  /* Стили для трека (основного фона) полосы прокрутки */
  ::-webkit-scrollbar-track {
    background-color: rgb(31 41 55 / var(--tw-bg-opacity)); /* Цвет трека */
  }
  
  /* Стили для бегунка (ползунка) полосы прокрутки */
  ::-webkit-scrollbar-thumb {
    background-color: #1d4ed8; /* Цвет бегунка */
    border-radius: 5px; /* Закругление углов бегунка */
  }
  
  /* Стили для кнопок в конце полосы прокрутки (необязательно) */
  ::-webkit-scrollbar-button {
    background-color: #ccc; 
    display: none;
  }